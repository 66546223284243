

























import { defineComponent } from '@vue/composition-api';
import { useAnnouncement } from '@/composition/announcements';

export default defineComponent({
  name: 'Announcement',
  props: {
    announcementId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { announcement } = useAnnouncement(props.announcementId);

    return {
      pageTitle: 'お知らせ',
      announcement,
    };
  },
});
